<template>
  <div
    v-if="chips.length"
    class="px-chips-container"
  >
    <VChip
      v-for="chip in chips"
      :key="chip"
      close-icon="mdi-delete"
      small
      close
      @click:close="removeChip(chip)"
    >
      {{ chip }}
    </VChip>
  </div>
</template>

<script>
export default {
  name: 'CMTableChip',

  props: {
    chips: {
      type: Array,
      default: () => ([])
    },

    filters: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    removeChip (chip) {
      const filterKey = chip.split(':').at(0)

      if (this.filters[filterKey]) {
        this.$emit('onUpdateKey', {
          key: 'filters',
          value: {
            ...this.filters,
            [filterKey]: {}
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.px-chips-container {
  padding: 15px;
  display: flex;
  gap: 10px;
}
</style>
